<template>
  <div class="wrapper">
    <base-button
      icon type="primary"
      class="flotingBtn"
      :class="[isActive ? 'tagBtn2' : 'tagBtn']"
      @click="isActive = !isActive"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-right"></i></span>
    </base-button>
    <base-button
      icon type="primary"
      class="flotingBtn2"
      @click="toggleClass"
      :class="[isActive ? 'tagBtn' : 'tagBtn2']"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-left"></i></span>
    </base-button>
    <Sidebar :class="{tagBtn: isActive}" />
    <div class="main-content" :class="{DashboardBody: isActive}">
        <SampleNavbar/>
        <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
            <b-breadcrumb>
                <b-breadcrumb-item href="#">Website</b-breadcrumb-item>
                <b-breadcrumb-item href="#" active>Info</b-breadcrumb-item>
            </b-breadcrumb>
        </base-header>
        <b-container fluid class="mt--7">
            <b-row>
                <b-col md="12">
                    <b-card type="default" header-classes="bg-transparent">
                        <b-form style="text-align: left">
                            <b-row>
                                <!-- <b-col md="3" class="mt-4">
                                    <label>Base Url *</label>
                                    <b-input type="text" placeholder="Base Url"></b-input>
                                </b-col> -->
                                <b-col md="3" class="mt-4">
                                    <label>Logo *</label>
                                    <b-input type="text" placeholder="Logo" v-model:value="form.logo"></b-input>
                                </b-col>
                                <b-col md="3" class="mt-4">
                                    <label>Name *</label>
                                    <b-input type="text" placeholder="Name" v-model:value="form.name"></b-input>
                                </b-col>
                                <b-col md="3" class="mt-4">
                                    <label>Phone 1 *</label>
                                    <b-input type="text" placeholder="Phone 1" v-model="form.phone1"></b-input>
                                </b-col>
                                <b-col md="3" class="mt-4">
                                    <label>Phone 2 *</label>
                                    <b-input type="text" placeholder="Phone 2" v-model="form.phone2"></b-input>
                                </b-col>
                                <b-col md="3" class="mt-4">
                                    <label>Email *</label>
                                    <b-input type="email" placeholder="Email" v-model="form.email"></b-input>
                                </b-col>
                                <b-col md="3" class="mt-4">
                                    <label>Address *</label>
                                    <b-input type="text" placeholder="Address" v-model="form.address"></b-input>
                                </b-col>
                                <b-col md="3" class="mt-4">
                                    <label>Facebook Url *</label>
                                    <b-input type="text" placeholder="Facebook Url" v-model="form.facebook"></b-input>
                                </b-col>
                                <b-col md="3" class="mt-4">
                                    <label>Youtube Url *</label>
                                    <b-input type="text" placeholder="Youtube Url" v-model="form.youtube"></b-input>
                                </b-col>
                                <b-col md="3" class="mt-4">
                                    <label>Telegram Url *</label>
                                    <b-input type="text" placeholder="Telegram Url" v-model="form.telegram"></b-input>
                                </b-col>
                                <b-col md="3" class="mt-4">
                                    <label>Twitter Url *</label>
                                    <b-input type="text" placeholder="Twitter Url" v-model="form.twitter"></b-input>
                                </b-col>
                                <b-col md="3" class="mt-4">
                                    <label>Copy *</label>
                                    <b-input type="text" placeholder="Copy" v-model="form.copy"></b-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="3" class="mt-4">
                                    <base-button block type="primary" @click="updateInfo" >Add Info</base-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
        <Footer/>
    </div>
  </div>
</template>
<script>
import Sidebar from './../../Starter/Sidebar'
import SampleNavbar from './../../Starter/SampleNavbar'
import Footer from './../../Starter/SampleFooter'
import { API_URL } from '../../../router/api'
export default {
  name: 'company-info',
  components: {
    Sidebar,
    SampleNavbar,
    Footer
  },
  data () {
    return {
      isActive: false,
      checked: true,
      form : {
        logo: null,
        name: null,
        phone1: null,
        phone2: null,
        email: null,
        address: null,
        facebook: null,
        youtube: null,
        telegram: null,
        twitter: null,
        copy: null,
      }
    }
  },
  beforeMount() {
    // this.$store.dispatch('getInfoShow');
  },
  computed: {
    info () {
      return this.$store.state.infoData == null ? {} : this.$store.state.infoData
    },
  },
  methods: {
    toggleClass: function (event) {
      this.isActive = !this.isActive
    },
    
    updateInfo() {
      console.log(this.form);
      this.axios.post(API_URL+'admin_info',this.form)
      .then((response) => {
        let obj = response.data
        obj.status === true ? this.$store.dispatch('alertSuccess', obj.message) : this.$store.dispatch('alertError', obj.message)
        this.$store.dispatch('getInfoShow')
        
      })
    }
  },
  mounted() {
    // this.$store.dispatch('getInfoShow');
    console.log(this.$store.state.infoData);
    this.form = {
      logo: this.info.info_logo,
      name: this.info.info_com_name,
      phone1: this.info.info_com_phone01,
      phone2: this.info.info_com_phone02,
      email: this.info.info_email,
      address: this.info.info_address,
      facebook: this.info.info_facebook,
      youtube: this.info.info_youtube,
      telegram: this.info.info_telegram,
      twitter: this.info.info_twitter,
      copy: this.info.info_copy,
    }
  }
}
</script>
<style lang="scss">
.DashboardBody {
  margin-left: 0px !important;
}
.tagBtn {
  display: none !important;
}
.tagBtn2 {
  display: block !important;
}
</style>
